
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap');

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #364f6b;
    --primary2-color: #5b7ea7;
    --secondary-color: #3FC1C9;
    --bg-color: #F5F5F5;
    --pink-color: #FC5185;

    /* text color: */
    --primary-text-color: #364F6B;
    --secondary-text-color: #8CB9BD;
    --white-text: #F5F5F5;
    --black-color: #45474b;
    --box-shadow: 0px 4px 6px 0px rgba(69, 71, 75, 0.5);
    /* font family */
    --normal-font-size: 1rem;
    --primary-font: 'Ubuntu', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;

    /* global variable */
    --header-height: 1.2rem;
    --nav-width: 68px;
    --white-color: #F7F6FB;

    /* font-size */
    --normal-font-size: 14px;

    /* z-index */
    --z-fixed: 100;
}

html {
    scroll-behavior: smooth;
}

/* custom scrollbar */

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

::-webkit-scrollbar-track {
    background: var(--bg-color);
}

a,
a:hover {
    text-decoration: none;
}

:active,
:hover,
:focus {
    outline: 0 !important;
    outline-offset: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

body {
    position: relative;
    width: 100%;
    height: 100vh;
    font-family: var(--secondary-font);
    transition: .5s;
    font-size: var(--normal-font-size);
    overflow-y: auto;
     margin: var(--header-height) 0 0 0;
    padding: 0 1rem; 
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .5rem;
    /* background-color: var(--white-color); */
    z-index: var(--z-fixed);
    transition: .5s;
}

.header__toggle {
    color: var(--pink-color);
    font-size: 1.76rem;
    padding-top: 2.45rem;
    cursor: pointer;
}


.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background: var(--primary-color);
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
    overflow-y: auto;
    overflow-x: hidden;
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.nav__logo,
.nav__link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
}

.nav__logo {
    margin-bottom: 1.43rem;
}

.nav__logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
}

.nav__logo-name {
    font-weight: 700;
    color: var(--white-color);
    font-size: 1.1rem;
}

.nav__link {
    position: relative;
    color: var(--white-text);
    margin-bottom: 5px;
    transition: .3s;
}

.nav__link:hover {
    color: var(--pink-color);
}

.nav__icon {
    font-size: 1.25rem;
}

.show {
    left: 0;
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem);
}

.active {
    color: var(--white-color);
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color);
}

section {
    padding: 100px 0;
}

/* navbar */

.main-btn {
    border: none;
    outline: none;
    background: transparent;
    color: var(--primary-text-color);
    border: 1px solid var(--pink-color);
    padding: 10px 27px;
    display: inline-block;
    cursor: pointer;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--primary-font);
    text-transform: capitalize;
    transition: 0.5s all ease-out;
    letter-spacing: 1px;
    background: linear-gradient(to right, var(--pink-color) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.main-btn:hover {
    color: var(--white-text);
    background-position: left bottom;
}

.header_wrapper .navbar {
    padding: 15px 0;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
    -o-transition: 0.2s all ease;
    -ms-transition: 0.2s all ease;
}

.header_wrapper .navbar-brand {
    font-size: 1.65rem;
    color: var(--pink-color);
}

.header_wrapper .nav-item {
    margin: 0 10px;
}

.header_wrapper .navbar-toggler:focus {
    box-shadow: none;
}

.header_wrapper .nav-item .nav-link {
    display: inline-block;
    letter-spacing: 1px;
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.header_wrapper .nav-item .nav-link:hover,
.header_wrapper .nav-item .nav-link.active {
    color: var(--pink-color);
}

.header-scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: var(--primary-color) !important;
    box-shadow: 0 4px 6px 0 rgba(12, 0, 46, .05);
}

.header-scrolled .nav-item .nav-link,
.header-scrolled .main-btn {
    color: var(--white-text) !important;
}

.header-scrolled .nav-item .nav-link.active {
    color: var(--pink-color) !important;
}

/* content css */
h1 {
    font-size: 3.56rem;
    line-height: 1.2;
    font-weight: 400;
    color: var(--black-color);
    margin-bottom: 28px;
    font-family: var(--primary-font);
    text-transform: capitalize;
}

h2 {
    font-size: 2.56rem;
    line-height: 1.2;
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 15px;
    font-family: var(--primary-font);
    text-transform: capitalize;
}

h3 {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 20px;
    font-family: var(--primary-font);
    text-transform: capitalize;
}

h3 span {
    color: var(--primary-text-color);
}

h5 {
    font-size: 25px;
    font-weight: 700;
    color: var(--primary-text-color);
}


p {
    font-size: 16px;
    color: var(--black-color);
    font-weight: 500;
}

h1 span {
    color: var(--pink-color);
}

.home-content {
    height: 100vh;
    width: 100%;
    box-shadow: var(--box-shadow);
    background: var(--white-text);
    margin: 1rem auto;
   padding: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-x: hidden;
}

.home-content .card {
    width: 30rem;
    max-width: 100%;
    text-align: center;
    border-radius: 7px;
    height: auto;
    margin: 1.67rem auto;

}

.home-content .card .card-body h5 {
    font-size: 1.453rem;
    font-weight: 600;
    color: var(--primary-text-color);
    margin-bottom: 10px;
}

.home-content .card .card-body p {
    font-size: .8375rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.home-content .card .card-body .input-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.home-content .card .card-body .input-box .main-btn {
    margin-bottom: 1.4rem;
}

.home-content .card .card-body .input-box .input {
    border: 1px solid var(--pink-color);
    width: 100%;
    outline: none;
    padding: 10px 1px;
    border-radius: 7px;
    margin-bottom: 1.34rem;
}

.home-content .card .card-body .input-box .input input[type="file"] {
    color: var(--primary-text-color);
    display: block;
    font-weight: 500;
    font-size: 11px;
}

/* banner extra content */
.banner_wrapper .text-content h3 {
    font-size: 2.67rem;
    text-align: center;
}

.banner_wrapper .text-content2 {
    flex-direction: column;
}

.banner_wrapper .text-content2 h5 {
    font-size: 18px;
    margin-top: 1.5rem;
}

/* contact css */
.contact_wrapper {
    background: var(--primary-color);
    text-align: center;
}

.contact_wrapper .copyright-text2 a {
    color: var(--white-text);
}

.contact_wrapper .copyright-text p {
    font-size: 13px;
    color: var(--white-text);
}

.contact_wrapper .copyright-text p a {
    color: var(--pink-color);
}


@media(max-width:991px) {

/* custom css */

h2 {
    font-size: 30px;
    line-height: 40px;
}

h3 {
    font-size: 20px;
}

section {
    padding: 50px 0 !important;
}

/* navbar design */
.header_wrapper .navbar-brand {
    font-size: 1.34rem !important;
}

.header_wrapper .navbar-collapse {
    margin-top: -2px;
}

.header-scrolled {
    height: auto;
}

.header_wrapper .navbar-toggler {
    color: var(--pink-color);
}

.header_wrapper .menu-navbar-nav {
    text-align: center;
    padding-bottom: 15px;
    background-color: var(--black-color);
    border-radius: 6px;
}

.header_wrapper .main-btn {
    color: var(--white-text) !important;
}

.header_wrapper .nav-item .nav-link {
    margin-top: 15px;
    color: var(--white-text) !important;
}

/* banner */
.home-content .card {
   width: 40rem !important; 
    margin: 0 auto;
}

}

@media screen and (min-width:768px) {
body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
}

.header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
}

.header__image {
    width: 40px;
    height: 40px;
}

.header__image img {
    width: 45px;
}

.l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
}

.show {
    width: calc(var(--nav-width) + 156px);
}

.body-pd {
    padding-left: calc(var(--nav-width) + 188px);
}

 /* banner */
.home-content .card{
width: 40rem !important;
margin: 0;
}
.home-content h1{
    font-size: 3rem;
}
.home-content p{
    font-size: 15px;
}

}

@media(max-width:530px){
  /* banner */
  .home-content .card {
    margin: 0 auto;
}

.home-content h1{
    font-size: 2.5657rem;
}
.home-content p{
    font-size: 14px;
}

/* banner */
.banner_wrapper .para{
    font-size: 13px;
    color:var(--black-color);
    font-weight: 600;
}
}

@media(max-width:350px){


.home-content .card .card-body .input-box .input {
    font-size: 7px;
}
.home-content h1{
    font-size: 36px !important;
}
.home-content p{
    font-size: 11px;
}
.home-content .card .card-body h5 {
    font-size: 17px !important;
    margin-bottom: 0;
}

/* banner */
.banner_wrapper .text-content h3{
    font-size: 33px;
}
}

@media(max-width:300px){
.home-content .card .card-body .input-box .input {
    font-size: 5px !important;
}

}

/* nave ne*/

.l-navbar.show {
    width: 250px; /* Expanded size */
}

.nav__logo-name,


.l-navbar.show .nav__logo-name,
.l-navbar.show .nav__link {
    opacity: 1;
}

/* Style for close button at the top-right of sidebar */
.nav__close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.nav__close i {
    font-size: 20px;
    color: white;
}

.nav__close:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
}
