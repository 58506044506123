
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap');

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #364f6b;
    --secondary-color: #3FC1C9;
    --bg-color: #F5F5F5;
    --pink-color: #FC5185;

    /* text color: */
    --primary-text-color: #364F6B;
    --secondary-text-color: #8CB9BD;
    --white-text: #F5F5F5;
    --black-color: #45474b;
    --box-shadow: 0px 4px 6px 0px rgba(69, 71, 75, 0.5);
    /* font family */

    --primary-font: 'Ubuntu', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
}

html {
    scroll-behavior: smooth;
}

/* custom scrollbar */

::-webkit-scrollbar{
    width: 6px;
}

::-webkit-scrollbar-thumb{
  background: var(--primary-color);
}
::-webkit-scrollbar-track{
    background: var(--bg-color);
}

a,
a:hover {
    text-decoration: none;
}

:active,
:hover,
:focus {
    outline: 0 !important;
    outline-offset: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

body {
    background: var(--bg-color);
    font-family: var(--secondary-font);
}

/* custom css */
section {
    padding: 100px 0;
}

.main-btn {
    border: none;
    outline: none;
    background: transparent;
    color: var(--primary-text-color);
    border: 1px solid var(--pink-color);
    padding: 10px 27px;
    display: inline-block;
    cursor: pointer;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--primary-font);
    text-transform: capitalize;
    transition: 0.5s all ease-out;
    letter-spacing: 1px;
    background: linear-gradient(to right, var(--pink-color) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.main-btn:hover {
    color: var(--white-text);
    background-position: left bottom;
}

h1 {
    font-size: 3.56rem;
    line-height: 1.2;
    font-weight: 400;
    color: var(--black-color);
    margin-bottom: 20px;
    font-family: var(--primary-font);
    text-transform: capitalize;
}

h2 {
    font-size: 2.56rem;
    line-height: 1.2;
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 15px;
    font-family: var(--primary-font);
    text-transform: capitalize;
}

h3 {
    font-size: 26px;
    line-height: 1.2;
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 20px;
    font-family: var(--primary-font);
    text-transform: capitalize;
}

h1 span {
    color: var(--pink-color);
}

h3 span {
    color: var(--primary-text-color);
}

h5 {
    font-size: 25px;
    font-weight: 700;
    color: var(--primary-text-color);
}

p {
    font-size: 18px;
    color: var(--black-color);
    font-weight: 500;
}

/* navbar css */
.header_wrapper .navbar {
    padding: 15px 0;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
    -o-transition: 0.2s all ease;
    -ms-transition: 0.2s all ease;
}

.header_wrapper .navbar-brand {
    font-size: 1.65rem;
    color: var(--pink-color);
}

.header_wrapper .nav-item {
    margin: 0 10px;
}

.header_wrapper .navbar-toggler:focus {
    box-shadow: none;
}

.header_wrapper .nav-item .nav-link {
    display: inline-block;
    letter-spacing: 1px;
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.header_wrapper .nav-item .nav-link:hover,
.header_wrapper .nav-item .nav-link.active {
    color: var(--pink-color);
}

.header-scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: var(--primary-color) !important;
    box-shadow: 0 4px 6px 0 rgba(12, 0, 46, .05);
}

.header-scrolled .nav-item .nav-link,
.header-scrolled .main-btn {
    color: var(--white-text) !important;
}
.header-scrolled .nav-item .nav-link.active{
    color:var(--pink-color) !important ;
}


/* banner css */

.banner_wrapper p {
    font-size: 14px;
    margin-bottom: 30px;
}

.banner_wrapper .banner-buttons-group {
    border: 1.5px solid var(--primary-color);
    border-radius: 10px;
    padding: 15px 20px;
    transition: all 0.2s ease-in;
    cursor: pointer;
    margin-top: 1.23rem;
    max-width: 100%;
    gap: 2;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    
}

.banner_wrapper .banner-buttons-group:hover {
    transform: scale(1.02);
    box-shadow: var(--box-shadow);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
}

.banner_wrapper .banner-buttons-group .btn01-head {
    display: flex;
    justify-content: left;
    align-items: start;
}

.banner_wrapper .banner-buttons-group .button01 img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-right: 13px;
}

.banner_wrapper .banner-buttons-group .button01 h3 {
    color: var(--primary-text-color);
}

.banner_wrapper .banner-buttons-group .button01 p {
    font-size: 12px;
    color: var(--black-color);
    text-align: left;
}

/* contact css */
.contact_wrapper{
    background: var(--primary-color);
    text-align: center;
}
.contact_wrapper .copyright-text2 a{
    color:var(--white-text);
}
.contact_wrapper .copyright-text p{
    font-size: 13px;
    color: var(--white-text);
}
.contact_wrapper .copyright-text p a{
    color: var(--pink-color);
}






@media(max-width:1199px) {
    
    .banner_wrapper .banner-buttons-group .button01 h3 {
        font-size: 21.32px;
    }

    .banner_wrapper .banner-buttons-group .button01 img {
        margin-right: 0;
    }
}

@media(max-width:991px) {

    /* custom css */

    h2 {
        font-size: 30px;
        line-height: 40px;
    }

    h3 {
        font-size: 20px;
    }
section{
    padding: 50px 0 !important;
}
    /* navbar design */
    .header_wrapper .navbar-brand {
        font-size: 1.34rem !important;
    }

    .header_wrapper .navbar-collapse {
        margin-top: -2px;
    }

    .header-scrolled {
        height: auto;
    }

    .header_wrapper .navbar-toggler {
        color: var(--pink-color);
    }

    .header_wrapper .menu-navbar-nav {
        text-align: center;
        padding-bottom: 15px;
        background-color: var(--black-color);
        border-radius: 6px;
    }

    .header_wrapper .main-btn {
        color: var(--white-text) !important;
    }

    .header_wrapper .nav-item .nav-link {
        margin-top: 15px;
        color: var(--white-text) !important;
    }


    /* banner media */
    .banner_wrapper h1 {
        font-size: 2.8875rem;
    }

    .banner_wrapper .banner-buttons-group .button01 h3 {
        font-size: 1.54rem;
    }

}

@media(max-width:767px) {
    .header_wrapper .navbar-brand {
        font-size: 20px !important;
    }

    /* banner media */
    .banner_wrapper h1 {
        font-size: 2.1468rem;
    }

    .banner_wrapper .banner-buttons-group .button01 h3 {
        font-size: 1.254rem;
    }


}

@media (max-width:540px) {

    /* banner media */
    .banner_wrapper h1 {
        font-size: 1.8785rem;
    }

    .banner_wrapper h3 {
        font-size: 1rem;
    }

    .banner_wrapper .banner-buttons-group .button01 img {
        width: 25px !important;
        height: 25px !important;
        object-fit: cover;
    }
}

@media (max-width:475px) {
     /* banner media */
     .banner_wrapper h1 {
        font-size: 1.5985rem;
    }
.banner_wrapper p{
    font-size: 11px !important;
}

   
}

@media(max-width:310px){
    .banner_wrapper .banner-buttons-group .button01 h3 {
        font-size: 1.093rem;
    }
    .banner_wrapper .banner-buttons-group .button01 img {
        width: 20px !important;
        height: 20px !important;
        object-fit: cover;
    }
}


/* new added below*/

/* banner */
.home-content .card {
    width: 40rem !important; 
     margin: 0 auto;
 }
 
 
 
 @media screen and (min-width:768px) {
 body {
     margin: calc(var(--header-height) + 1rem) 0 0 0;
     padding-left: calc(var(--nav-width) + 2rem);
 }
 
 .header {
     height: calc(var(--header-height) + 1rem);
     padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
 }
 
 .header__image {
     width: 40px;
     height: 40px;
 }
 
 .header__image img {
     width: 45px;
 }
 
 .l-navbar {
     left: 0;
     padding: 1rem 1rem 0 0;
 }
 
 .show {
     width: calc(var(--nav-width) + 156px);
 }
 
 .body-pd {
     padding-left: calc(var(--nav-width) + 188px);
 }
 
  /* banner */
 .home-content .card{
 width: 40rem !important;
 margin: 0;
 }
 .home-content h1{
     font-size: 3rem;
 }
 .home-content p{
     font-size: 15px;
 }
 
 }
 
 @media(max-width:530px){
   /* banner */
   .home-content .card {
     margin: 0 auto;
 }
 
 .home-content h1{
     font-size: 2.5657rem;
 }
 .home-content p{
     font-size: 14px;
 }
 
 /* banner */
 .banner_wrapper .para{
     font-size: 13px;
     color:var(--black-color);
     font-weight: 600;
 }
 }
 
 @media(max-width:350px){
 
 
 .home-content .card .card-body .input-box .input {
     font-size: 7px;
 }
 .home-content h1{
     font-size: 36px !important;
 }
 .home-content p{
     font-size: 11px;
 }
 .home-content .card .card-body h5 {
     font-size: 17px !important;
     margin-bottom: 0;
 }
 
 /* banner */
 .banner_wrapper .text-content h3{
     font-size: 33px;
 }
 }
 
 @media(max-width:300px){
 .home-content .card .card-body .input-box .input {
     font-size: 5px !important;
 }
 
 }



 /* new 2*/
 
 
.home-content {
    height: 100vh;
    width: 100%;
    box-shadow: var(--box-shadow);
    background: var(--white-text);
    margin: 1rem auto;
   padding: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-x: hidden;
}

.home-content .card {
    width: 30rem;
    max-width: 100%;
    text-align: center;
    border-radius: 7px;
    height: auto;
    margin: 1.67rem auto;

}

.home-content .card .card-body h5 {
    font-size: 1.453rem;
    font-weight: 600;
    color: var(--primary-text-color);
    margin-bottom: 10px;
}

.home-content .card .card-body p {
    font-size: .8375rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.home-content .card .card-body .input-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.home-content .card .card-body .input-box .main-btn {
    margin-bottom: 1.4rem;
}

.home-content .card .card-body .input-box .input {
    border: 1px solid var(--pink-color);
    width: 100%;
    outline: none;
    padding: 10px 1px;
    border-radius: 7px;
    margin-bottom: 1.34rem;
}

.home-content .card .card-body .input-box .input input[type="file"] {
    color: var(--primary-text-color);
    display: block;
    font-weight: 500;
    font-size: 11px;
}


