.privacy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-container h4 {
    color: #474b4f;
    font-size: 20px;
    margin: 16px 0;
  }
  
  .privacy-container p {
    color: #474b4f;
    font-size: 14px;
    margin: 0 0 16px;
  }
  
  .privacy-container ul {
    list-style-position: inside;
    margin: 0 0 16px;
    padding: 0;
  }
  
  .privacy-container ul li {
    margin: 0;
    padding: 0;
  }